import NewMissionForm from "./components/NewMissionForm";




export default function App() {


  return (
    <div className="overflow-hidden px-3 py-10 bg-gray-200 flex justify-center">
      <div className="w-full max-w-xl">
        <NewMissionForm></NewMissionForm>
      </div>
    </div>
  );
}